import styled from "@emotion/styled";
import React from "react";
import useLanguage from "@src/hooks/useLanguage";

interface IProps {
  onLight?: boolean;
}

const Root = styled.a<IProps>`
  display: block;
  position: relative;
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 24px;
  cursor: pointer;
  text-decoration: none;
  width: 140px;
  object-fit: contain;
`;

const Logo: React.FC<IProps> = ({ onLight }) => {
  const dictionary = useLanguage();
  const iconColor = onLight ? '#E44621' : '#272525';
  const textColor = onLight ? '#272525' : '#FFFFFF';

  return (
    <Root href="/">
      <svg width="140px" height="70px" viewBox="0 0 210 70" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M129.301 32.4h-3.1v-4c-.2.2-.6.5-1 .6-.4.2-.9.3-1.3.3-1.3 0-2.4-.5-3.2-1.5-.8-1-1.2-2.4-1.2-4.1 0-1.7.4-3.1 1.2-4.1.8-1 1.9-1.5 3.2-1.5.5 0 .9.1 1.3.3.4.2.7.4 1 .7v-4.2h3.1v4.2c.2-.3.6-.5 1-.7.4-.2.9-.3 1.3-.3 1.3 0 2.4.5 3.2 1.5.8 1 1.2 2.4 1.2 4.1 0 1.7-.4 3.1-1.2 4.1-.8 1-1.9 1.5-3.2 1.5-.5 0-.9-.1-1.3-.3-.4-.2-.7-.4-1-.6v4Zm-4.5-11.7c-.7 0-1.3.3-1.7.8-.4.6-.6 1.3-.6 2.2 0 .9.2 1.7.6 2.2.4.5 1 .8 1.7.8.4 0 .9-.2 1.3-.5v-5c-.4-.3-.9-.5-1.3-.5Zm5.8 0c-.4 0-.8.2-1.3.5v5c.4.3.9.5 1.3.5.7 0 1.3-.3 1.7-.8.4-.5.6-1.3.6-2.2 0-.9-.2-1.6-.6-2.2-.4-.5-1-.8-1.7-.8ZM89.501 32.4V18.2h3.1v1.6c.9-1.2 2-1.8 3.4-1.8s2.5.5 3.5 1.5 1.5 2.4 1.5 4.2c0 1.7-.5 3.1-1.5 4.1-1 1-2.1 1.5-3.5 1.5s-2.5-.5-3.4-1.6v4.7h-3.1Zm3.9-6.5c.5.6 1.1.8 1.9.8.7 0 1.4-.3 1.9-.8.5-.6.8-1.3.8-2.2 0-.9-.3-1.6-.8-2.2-.5-.6-1.1-.8-1.9-.8-.7 0-1.4.3-1.9.8-.5.6-.8 1.3-.8 2.2 0 .9.2 1.6.8 2.2Z" fill={textColor}/>
        <path d="M85.201 18.2v10.9h-3.1v-8.3h-3.7v8.3h-3.1V18.2h9.9Z" fill={textColor}/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M110.201 29.5c1.7 0 3.1-.6 4.2-1.7s1.7-2.5 1.7-4c0-1.6-.6-2.9-1.7-4s-2.5-1.6-4.2-1.6c-1.7 0-3.1.6-4.2 1.7s-1.7 2.4-1.7 4 .6 2.9 1.7 4 2.5 1.6 4.2 1.6Zm2.1-3.7c-.6.6-1.2.9-2.1.9-.8 0-1.5-.3-2.1-.9-.5-.6-.8-1.3-.8-2.1 0-.9.3-1.6.8-2.2.6-.6 1.2-.9 2.1-.9.9 0 1.6.3 2.1.9.5.6.8 1.3.8 2.1 0 .9-.3 1.6-.8 2.2ZM140.901 27.8c1.1 1.1 2.4 1.6 4.1 1.6 1.9 0 3.4-.7 4.6-2l-1.8-1.6c-.8.7-1.6 1.1-2.6 1.1-.8 0-1.3-.2-1.8-.6-.4-.4-.8-.9-.9-1.6h7.6v-.8c0-1.7-.5-3.1-1.4-4.2-1-1.1-2.3-1.7-4-1.7-1.6 0-2.9.6-3.9 1.7s-1.5 2.4-1.5 4c0 1.7.5 3 1.6 4.1Zm6.1-5h-4.7c.1-.7.4-1.2.8-1.7.5-.4 1-.6 1.6-.6.7 0 1.2.2 1.6.6.4.4.6 1 .7 1.7Z" fill={textColor}/>
        <path d="M159.001 29.4c-1.6 0-3-.6-4.1-1.7-1.1-1.1-1.6-2.4-1.6-4s.5-2.9 1.6-4 2.5-1.7 4.1-1.7c1.8 0 3.2.6 4.3 1.9l-1.8 1.8c-.8-.7-1.6-1.1-2.5-1.1-.8 0-1.4.3-1.9.9-.5.6-.8 1.3-.8 2.1 0 .9.3 1.6.8 2.2.5.6 1.2.9 2 .9.9 0 1.7-.4 2.5-1.2l1.8 1.8c-.6.7-1.2 1.1-1.9 1.5-.6.4-1.5.6-2.5.6ZM168.101 27.7c1.1 1.1 2.5 1.7 4.1 1.7 1 0 1.8-.2 2.5-.6.7-.4 1.3-.8 1.9-1.5l-1.8-1.8c-.8.8-1.6 1.2-2.5 1.2-.8 0-1.5-.3-2-.9-.5-.6-.8-1.3-.8-2.2 0-.8.3-1.5.8-2.1.5-.6 1.1-.9 1.9-.9.9 0 1.7.4 2.5 1.1l1.8-1.8c-1.1-1.3-2.5-1.9-4.3-1.9-1.6 0-3 .6-4.1 1.7-1.1 1.1-1.6 2.4-1.6 4s.5 2.9 1.6 4ZM190.301 18.2v10.9h-3v-6.4l-4.3 6.4h-2.9V18.2h3v6.4l4.3-6.4h2.9ZM204.801 29.1V18.2h-2.9l-4.3 6.4v-6.4h-3v10.9h2.9l4.3-6.4v6.4h3Z" fill={textColor}/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M81.5 53c-2.1 0-3.7-.6-4.7-1.7-1.1-1.1-1.6-2.7-1.6-4.8 0-.7 0-1.4.1-2 .1-.6.2-1.2.4-1.8.2-.7.5-1.2.8-1.7.4-.5.9-.9 1.5-1.2.6-.4 1.4-.6 2.3-.8l6.6-1.1.5 2.4-6.7 1.2c-1.9.3-2.8 1.2-3 2.6.3-.4.8-.9 1.6-1.3s1.7-.6 2.9-.6c1.6 0 2.9.5 4.1 1.5 1.1 1 1.7 2.2 1.7 3.7s-.6 2.8-1.8 3.9c-1.3 1.2-2.8 1.7-4.7 1.7Zm.1-2.6c.9 0 1.6-.3 2.1-.8s.8-1.2.8-2-.3-1.5-.9-2c-.6-.6-1.3-.8-2.1-.8-.9 0-1.6.3-2.1.8-.5.6-.8 1.2-.8 2s.3 1.5.9 2c.5.5 1.2.8 2.1.8Z" fill={textColor}/>
        <path d="M91.9 55.3c1 .5 2 .8 3.1.8s1.9-.2 2.5-.7c.6-.5 1.1-1.3 1.6-2.4l4.7-11.2h-3.6l-2.7 7.4-2.8-7.4H91l4.8 11c-.2.5-.7.7-1.2.7s-1-.1-1.5-.4l-1.2 2.2Z" fill={textColor}/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M117.001 52.8h-7.7l-.3 2.6h-2.9v-5.2h1.3c.8-1.5 1.2-4 1.2-7.3V42h10v8.3h1.7l-.4 5.2h-2.9v-2.7Zm-6-2.7h4.2v-5.6h-3.2v.2c-.1 2.4-.4 4.2-1 5.4Z" fill={textColor} />
        <path d="M123.8 55.3c1 .5 2 .8 3.1.8 1 0 1.8-.2 2.4-.7.6-.5 1.1-1.3 1.6-2.4l4.8-11.2h-3.6l-2.7 7.4-2.8-7.4h-3.7l4.8 11c-.2.5-.7.7-1.2.7s-1-.1-1.5-.4l-1.2 2.2ZM139.4 52.8V41.9h3.5v8.3h3.9v-8.3h3.5v8.3h3.9v-8.3h3.5v8.3h1.7l-.4 5.2h-2.9v-2.6h-16.7Z" fill={textColor}/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M164.1 51.4c1.2 1.1 2.7 1.6 4.6 1.6 2.1 0 3.7-.7 5-2l-2-1.6c-.9.7-1.9 1.1-3 1.1-.8 0-1.5-.2-2-.6-.5-.4-.8-.9-1-1.6h8.5v-.8c0-1.7-.5-3.1-1.5-4.2s-2.5-1.7-4.4-1.7c-1.7 0-3.2.6-4.3 1.7-1.1 1.1-1.7 2.4-1.7 4 0 1.7.6 3 1.8 4.1Zm6.8-4.9h-5.2c.1-.8.4-1.3 1-1.7.4-.4 1-.6 1.7-.6s1.3.2 1.7.6c.4.4.7 1 .8 1.7Z" fill={textColor}/>
        <path d="M187.4 44.5H182v8.3h-3.5V41.9h8.9v2.6Z" fill={textColor}/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M197.1 53.1c1.9 0 3.4-.6 4.7-1.7 1.3-1.1 1.9-2.5 1.9-4 0-1.6-.6-2.9-1.9-4-1.2-1.1-2.8-1.6-4.7-1.6-1.9 0-3.4.6-4.7 1.7-1.3 1.1-1.9 2.4-1.9 4s.6 2.9 1.9 4c1.2 1.1 2.8 1.6 4.7 1.6Zm2.3-3.6c-.6.6-1.3.9-2.3.9-.9 0-1.7-.3-2.3-.9-.6-.6-.9-1.3-.9-2.1 0-.9.3-1.6.9-2.2.6-.6 1.3-.9 2.3-.9.9 0 1.7.3 2.3.9.6.6.9 1.3.9 2.1 0 .9-.3 1.6-.9 2.2Z" fill={textColor}/>
        <path d="m33.4 13.4 4.2-2.4c-.3-.2-.6-.3-.9-.4-1.163-.735-2.364-1.432-3.58-2.138-.769-.447-1.544-.897-2.32-1.362-.5-.3-.8-.3-1.3 0-3.9 2.3-7.7 4.5-11.6 6.7-.5.3-.6.6-.6 1.1v20.3c0 .3-.1.6-.3.8-.6.7-.6 1.7 0 2.3.6.6 1.6.6 2.2 0 .7-.7.7-1.7 0-2.3-.3-.2-.4-.5-.4-.9V22.5c0-.5.2-.8.6-1 2.35-1.35 4.675-2.7 6.999-4.049h.002c2.325-1.351 4.65-2.701 7-4.051Z" fill={iconColor}/>
        <path d="M54.851 26c.575.325 1.15.65 1.75 1 0-.2 0-.2.2-.3v-7.9c0-.2-.2-.5-.4-.6-1.95-1.15-3.926-2.275-5.9-3.4a452.058 452.058 0 0 1-5.9-3.4c-.3-.2-.6-.2-.9 0-5.5 3.1-11.1 6.3-16.6 9.5-.21.093-.407.196-.602.298-.645.337-1.253.656-2.098.502-.8-.1-1.5.6-1.5 1.4-.1.8.5 1.6 1.3 1.7.7.1 1.6-.4 1.7-1.1 0-.5.3-.7.7-.9 1.8-1.05 3.574-2.075 5.35-3.1 1.774-1.025 3.55-2.05 5.35-3.1.4-.3.8-.3 1.2 0 4.8 2.8 9.7 5.6 14.6 8.4.6.35 1.175.675 1.75 1ZM14.3 19.5v21.1c0 .4.1.6.5.8 1.8 1.05 3.624 2.1 5.448 3.15h.002c1.825 1.05 3.65 2.1 5.45 3.15.3.2.6.3 1.1.2.8-.3 1.7.3 1.9 1.1.2.9-.3 1.7-1.2 1.9-.8.2-1.6-.4-1.8-1.3-.1-.2-.3-.5-.5-.6-5.1-3-10.2-5.9-15.3-8.8-.4-.25-.825-.5-1.25-.75-.425-.25-.85-.5-1.25-.75-.3-.2-.4-.4-.4-.7V24.1c0-.2.1-.5.3-.6 1.1-.65 2.225-1.3 3.35-1.95 1.125-.65 2.25-1.3 3.35-1.95 0 0 .1 0 .3-.1Z" fill={iconColor}/>
        <path d="M52.3 50.6v.8h.2c2.3-1.4 4.5-2.7 6.8-4 .1 0 .2-.3.2-.5V33.2c0-.4-.1-.7-.5-.9-2.9-1.7-5.825-3.4-8.75-5.1-2.925-1.7-5.85-3.4-8.75-5.1-.4-.2-.6-.4-.7-.9-.2-.8-1.1-1.3-1.9-1.1-.9.2-1.3 1-1.1 1.9.3.9 1 1.4 1.9 1.1.4-.2.7-.1 1 .1 1.8 1.05 3.625 2.075 5.45 3.1 1.825 1.025 3.65 2.05 5.45 3.1.5.2.6.5.6 1 0 3.413.026 6.8.05 10.175.026 3.35.05 6.688.05 10.025Z" fill={iconColor}/>
        <path d="M28.9 60a346.31 346.31 0 0 0 2.282-1.319c1.29-.75 2.554-1.485 3.818-2.181 2.05-1.2 4.075-2.375 6.1-3.55 2.025-1.175 4.05-2.35 6.1-3.55.2-.1.4-.4.4-.7V35.8c0-.3-.1-.6-.3-.8-.6-.7-.7-1.6 0-2.3.7-.6 1.7-.6 2.3 0 .6.7.5 1.6-.2 2.3-.2.2-.3.4-.3.7v20.5c0 .5-.1.7-.6 1-1.95 1.15-3.9 2.275-5.85 3.4-1.95 1.125-3.9 2.25-5.85 3.4-.3.2-.5.3-.8.1-1.1-.65-2.225-1.3-3.35-1.95-1.125-.65-2.25-1.3-3.35-1.95a.525.525 0 0 0-.209-.118c-.052-.02-.114-.044-.19-.082Z" fill={iconColor}/>
        <path d="M13.6 46.1 10 44c0 .2-.1.2-.1.2v7.9c0 .1.2.4.4.5 3.9 2.3 7.899 4.6 11.898 6.899l.002.001c.3.3.6.2.9 0h.001c5.8-3.3 11.6-6.6 17.4-10 .5-.3.9-.4 1.4-.3.8.3 1.6-.3 1.8-1.2.2-.8-.4-1.6-1.2-1.8-.9-.2-1.7.3-1.9 1.2-.1.3-.3.6-.5.7-1.318.769-2.65 1.538-3.99 2.311-2.32 1.34-4.665 2.694-7.01 4.089-.3.2-.6.2-.9 0-4.8-2.8-9.7-5.6-14.6-8.4Z" fill={iconColor}/>
      </svg>
    </Root>
  );
};

export default Logo;
