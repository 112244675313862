import styled from "@emotion/styled";
import React, { useState } from "react";
import { useStores } from "@stores";
import { useObserver } from "mobx-react-lite";
import { useAlert } from "react-alert";
import { Row } from "@components/flex";
import Title from "@components/fontComponents/Title";
import ToggleArrow from "@components/ToggleArrow";
import Subtitle from "@components/fontComponents/Subtitle";
import Modal from "@src/Modal";
import DirectBtn from "@components/DirectBtn";
import Button from "@components/Button";
import { ROUTES } from "@stores/RouterStore";
import { useHistory } from "react-router-dom";
import successFace from "@src/assets/successFace.svg";
import LinkOutput from "@components/UpdateAddLinkTab/LinkInput/LinkOutput";
import useLanguage from "@src/hooks/useLanguage";

interface IProps {}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 30px;
`;
const NoLinksScreen = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
`;
const DirectMode: React.FC<IProps> = () => {
  const [opened, setOpened] = useState(true);
  const { cardStore, accountStore } = useStores();
  const dictionary = useLanguage();
  const history = useHistory();
  const links = useObserver(() =>
    cardStore.card.socialLinks?.concat(cardStore.card.payments!)
  );
  const [modalOpened, setModalOpened] = useState<boolean>(false);
  const alert = useAlert();
  const toggleDirect = async () => {
    try {
      if (cardStore.card.direct == null) {
        setModalOpened(true);
        return;
      } else {
        await cardStore.setDirect();
      }
    } catch (e: any) {
      alert.error(e.toString());
    }
  };
  return useObserver(() => (
    <Root>
      {accountStore.user?.firebase_uid === cardStore.card.userId ? (
        <>
          <Row
            onClick={() => setOpened(!opened)}
            style={{ marginBottom: 12, cursor: "pointer", userSelect: "none" }}
            alignItems="center"
            justifyContent="space-between"
          >
            <Title>
              Direct mode{" "}
              {cardStore.card.direct == null
                ? dictionary.directModeTab.off
                : dictionary.directModeTab.on}
            </Title>
            <ToggleArrow opened={opened} />
          </Row>
          {opened && (
            <>
              <Subtitle>{dictionary.directModeTab.description}</Subtitle>
              <DirectBtn
                text={
                  cardStore.card.direct == null
                    ? dictionary.directModeTab.turnOn
                    : dictionary.directModeTab.turnOff
                }
                onClick={toggleDirect}
              />
              {modalOpened && (
                <Modal
                  title="Direct mode"
                  subtitle={
                    links?.length === 0
                      ? undefined
                      : dictionary.directModeTab.chooseLink
                  }
                  onClose={() => setModalOpened(false)}
                >
                  {links?.length === 0 ? (
                    <NoLinksScreen>
                      <img src={successFace} alt="oops" />
                      <Title style={{ textAlign: "center" }}>
                        {dictionary.directModeTab.addLinkToUseDirectMode}
                      </Title>
                      <Button
                        onClick={() => history.push(ROUTES.EDIT)}
                        style={{ marginTop: 25 }}
                      >
                        {dictionary.add}
                      </Button>
                    </NoLinksScreen>
                  ) : null}
                  <div style={{ height: "100%" }}>
                    {links &&
                      links
                        .filter((link) => {
                          if (
                            link.type === "sberbank" ||
                            link.type === "qiwi"
                          ) {
                            return false;
                          }
                          return !(
                            link.value.startsWith("https://kasanie") ||
                            link.value.startsWith("https://localhost")
                          );
                        })
                        .map((link, index) => (
                          <LinkOutput
                            textColor="#E44621"
                            type={link.type}
                            displayName={link.value}
                            key={index}
                            onClick={async () => {
                              await cardStore.setDirect(link.value);
                              setModalOpened(false);
                            }}
                          />
                        ))}
                  </div>
                </Modal>
              )}
            </>
          )}
        </>
      ) : null}
    </Root>
  ));
};

export default DirectMode;
