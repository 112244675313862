import styled from "@emotion/styled";
import React from "react";
import Button from "@components/Button";
import successFace from "@src/assets/successFace.svg";
import problemFace from "@src/assets/problemFace.svg";
import Header from "@components/Header";
import { layoutStyle } from "@components/Layout";
import TextButton from "@components/TextButton";

export enum RESULT_SCREEN_TYPE {
  SUCCESS = "success",
  ERROR = "error",
}

interface IProps {
  title: string;
  subtitle?: string;
  btnText?: string;
  onButtonClick?: () => void;
  type?: RESULT_SCREEN_TYPE;
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${layoutStyle};
  position: relative;
  width: 100%;
`;

const Title = styled.div`
  font-family: Montserrat;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  text-align: center;
  color: #000000;
  margin-top: 20px;
`;

const Subtitle = styled.div`
  font-family: Graphik LCG;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #000000;
  margin-top: 12px;
`;

const ResultScreen: React.FC<IProps> = ({
  title,
  btnText,
  onButtonClick,
  type = "success",
  subtitle,
}) => (
  <Root>
    <Header style={{ marginBottom: 56 }} logoOnLight={true} />
    {type === RESULT_SCREEN_TYPE.SUCCESS ? (
      <img src={successFace} alt="oops" />
    ) : (
      <img src={problemFace} alt="oops" />
    )}
    <Title style={{ marginTop: 20 }}>{title}</Title>
    {subtitle && <Subtitle>{subtitle}</Subtitle>}
    <Button style={{ marginTop: 24 }} onClick={onButtonClick}>
      {btnText ?? "Продолжить"}
    </Button>
    {type === RESULT_SCREEN_TYPE.ERROR && (
      <TextButton
        style={{ position: "fixed", bottom: 51 }}
        onClick={() =>
          window.location.replace("https://www.t.me/kasanie_support/")
        }
      >
        Помощь
      </TextButton>
    )}
  </Root>
);
export default ResultScreen;
