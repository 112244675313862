import styled from "@emotion/styled";
import React from "react";
import {
  PAYMENT_NAMES,
  SOCIAL_ICONS,
  SOCIAL_NAMES,
} from "@src/assets/socialIconsConfig";
import { isAndroid } from "react-device-detect";

interface IProps extends React.HTMLProps<HTMLDivElement> {
  type: string;
  value?: string;
  displayName?: string;
  bordered?: boolean;
  textColor?: string;
}

const Root = styled.div<{ bordered?: boolean }>`
  display: flex;
  border-radius: 6px;
  background-color: #ffffff;
  align-items: center;
  margin: 5px;
  padding: 11px 12px;
  flex: 1;
  min-width: calc(50% - 10px);
  max-width: calc(50% - 10px);
  box-sizing: border-box;
  ${({ bordered }) => bordered && "border: 1px solid #e6e7e9"};
  cursor: pointer;
`;
const Text = styled.div<{ color?: string }>`
  font-family: Graphik LCG;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: ${({ color }) => color ?? "var(--accent-color)"};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const Icon = styled.img`
  width: 30px;
  height: 30px;
  padding-right: 10px;
`;
const LinkComponent: React.FC<IProps> = ({
  type,
  value,
  displayName,
  bordered,
  textColor,
  ...rest
}) => {
  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (type === "sberbank" && value) {
      window.open(`sms:900&body=Перевод%20${value}%20500%20рублей`, "_blank");
      return;
    }
    if (type === "qiwi" && value) {
      window.open(`https://qiwi.com/p/${value}`, "_blank");
      return;
    }

    if (value) window.open(value, "_blank");
    if (rest.onClick != null) rest.onClick(e);
  };
  if (isAndroid && type === "sberbank") return null;
  return (
    <Root onClick={handleClick} bordered={bordered}>
      {SOCIAL_ICONS[type] && <Icon src={SOCIAL_ICONS[type]} alt="icon" />}
      <Text color={textColor}>
        {displayName != null && displayName !== ""
          ? displayName
          : SOCIAL_NAMES[type] || PAYMENT_NAMES[type]}
      </Text>
    </Root>
  );
};
export default LinkComponent;
