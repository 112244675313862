/** @jsx jsx */
import { css } from "@emotion/core";
import styled from "@emotion/styled";

export enum BUTTON_TYPES {
  WHITE = "white",
  DEFAULT = "default",
}

interface IProps {
  color?: string;
  background?: string;
  buttonType?: BUTTON_TYPES;
}

export const buttonStyle = css`
  text-decoration: none;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Graphik LCG;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;

  box-sizing: border-box;
  cursor: pointer;
  width: 100%;
  border: none;
  box-shadow: none;
  outline: none;
  height: 48px;
  :disabled {
    background: #b9b9b9;
    cursor: not-allowed;
  }
`;

const Button = styled.button<IProps>`
  ${buttonStyle};
  color: ${({ color, buttonType }) =>
    color ?? buttonType === BUTTON_TYPES.WHITE ? "var(--accent-color)" : "#ffffff"};
  background: ${({ background, buttonType }) =>
    background ?? buttonType === BUTTON_TYPES.WHITE ? "#ffffff" : "var(--accent-color)"};
`;

export const ButtonLink = styled.a<IProps & { disabled?: boolean }>`
  ${buttonStyle};
  color: ${({ color }) => color ?? "#ffffff"};
  background: ${({ background, disabled }) =>
    disabled ? "#B9B9B9" : background ?? "var(--accent-color)"};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
`;

export default Button;
