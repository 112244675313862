import styled from "@emotion/styled";
import React, { useState } from "react";
import { useAlert } from "react-alert";
import CameraIcon from "@src/screens/UpdateScreen/CameraIcon";
import Header from "@components/Header";
import Loading from "@components/Loading";
import { compressImage, toBase64, toFile } from "@utils";

interface IProps {
  image: string | undefined;
  onChange: (image: File) => void;
  loading: boolean;
  setLoading: (loading: boolean) => void;
}

const Root = styled.div<{ image?: string }>`
  position: relative;
  .upload-btn-wrapper {
    border-radius: 0 0 25px 25px;
    position: relative;
    background: #f7f7f5;
    height: 207px;
  }
  .btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 207px;
    color: #ffffff;
  }
  .upload-btn-wrapper input[type="file"] {
    cursor: pointer;
    width: 50px;
    height: 50px;
    position: absolute;
    opacity: 0;
    bottom: -25px;
  }
`;
const ImageUpload: React.FC<IProps> = ({ onChange, image, ...rest }) => {
  const alert = useAlert();
  const [base64Photo, setBase64Photo] = useState<string | null>(null);
  const handleChange = async ({
    target: { files },
  }: React.ChangeEvent<HTMLInputElement>) => {
    if (!files || !files[0]) return;
    const file: File = files[0];
    if (!/(gif|jpe?g|tiff?|png|bmp)$/i.test(file.type)) {
      alert.error("Пожалуйста, выберите, файлы другого расширения");
    }
    rest.setLoading(true);
    try {
      const b64 = await toBase64(file);
      const compressed = await compressImage(b64);
      setBase64Photo(compressed);
      onChange && (await onChange(toFile(compressed)));
      rest.setLoading(false);
    } catch (e: any) {
      rest.setLoading(false);
    }
  };
  return (
    <Root>
      <div
        className="upload-btn-wrapper"
        style={{
          backgroundImage:
            image != null && !rest.loading
              ? `url(${base64Photo ?? image})`
              : "background: rgba(0, 0, 0, 0.3)",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        {rest.loading && (
          <Loading color="#ffffff" style={{ maxHeight: "100%" }} />
        )}
        <Header
          logoOnLight={true}
          style={{ padding: "15px 16px", boxSizing: "border-box" }}
        />
        <CameraIcon />
        <div className="btn">
          <input
            accept="image/*"
            disabled={rest.loading}
            type="file"
            name="file"
            onChange={handleChange}
          />
        </div>
      </div>
    </Root>
  );
};
export default ImageUpload;
